//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { getCol, docIdKey } from '@happstv/shared/util/firebase/firestoreUtils'
import { AGENCY_MEDIA_KITS_COL_PATH } from '@happstv/shared/util/firebase/firestorePaths'
import { numberMagnitudeString } from '@happstv/shared/util/utils'
import { TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE, SNAPCHAT_NATIVE } from '@happstv/shared/util/multicastingConstants'
import { getAccountListFromMediaKit } from '@/util/agent/agencyUtils'

import ReachLogo from '@/assets/svg/app/ReachLogo.vue'
import PopupLayer from '@/components/universal/overlapping/PopupLayer.vue'
import AgencyLogo from '@/components/agent/AgencyLogo.vue'
import PublicMediaKitPreview from '@/components/agent/creator/mediaKit/PublicMediaKitPreview.vue'
import TiktokIcon from '@/assets/svg/social/external/TiktokIcon.vue'
import InstagramIcon from '@/assets/svg/social/external/InstagramIconWhite.vue'
import YoutubeIcon from '@/assets/svg/social/external/YoutubeIcon.vue'
import SnapchatIcon from '@/assets/svg/social/external/SnapchatIcon.vue'

const PublicMediaKitFrame = () => import('./PublicMediaKitFrame.vue')

const SORT_END_CHAR = 'Ξ' // this char comes after all of the alphanumeric chars

export default {
  name: 'PublicCreatorList',
  props: {
    creatorList: Object,
  },
  components: {
    ReachLogo,
    PopupLayer,
    AgencyLogo,
    PublicMediaKitPreview,
  },
  data() {
    return {
      numberMagnitudeString,
      mediaKitList: undefined,
      mediaKitPreview: undefined,
      PublicMediaKitFrame,
      YOUTUBE_NATIVE,
    }
  },
  computed: {
    ...mapState([
      'isMobile',
    ]),
    agencyData() { return (this.creatorList || {}).agencyData || {} },
    creatorListId() { return (this.creatorList || {}).id },
    aggregatedFollowersByPlatform() {
      const { mediaKitList } = this
      if (!mediaKitList) return {}

      const result = {}

      mediaKitList
        .map(getAccountListFromMediaKit)
        .flat()
        .filter(({ account: { platform } = {} }) => [TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE].includes(platform))
        .forEach(({ account: { platform, followerCount: backupCount } = {}, userResultStats: { followerCount } = {} }) => {
          result[platform] = (result[platform] || 0) + (followerCount || backupCount || 0)
        })

      return result
    },
    hideRates() { return (this.creatorList || {}).hideRates },
  },
  watch: {
    creatorListId: {
      immediate: true,
      async handler(creatorListId) {
        if (creatorListId) {
          const { creatorList = {} } = this
          const { agencyId, agentUserId, includeFullRoster, selectedMediaKitIdList = [], isAgencyWide } = creatorList

          if (!includeFullRoster && !selectedMediaKitIdList.length) { // This is because the "in" query breaks if selectedMediaKitIdList is empty
            this.mediaKitList = []
          } else {
            const mediaKitQueryAdditions = (() => {
              if (includeFullRoster) {
                if (isAgencyWide) return q => q.where('agencyId', '==', agencyId)
                return q => q.where('agencyId', '==', agencyId).where('agentUserId', '==', agentUserId)
              }
              return q => q.where((docIdKey()), 'in', selectedMediaKitIdList)
            })()

            const mediaKitsSnap = await getCol(AGENCY_MEDIA_KITS_COL_PATH, q => mediaKitQueryAdditions(q).orderBy('createDate', 'asc'))

            const fullMediaKitList = (mediaKitsSnap.docs || [])
              .map(mediaKit => ({ id: mediaKit.id, ...(mediaKit.data() || {}) }))

            const uniqueIdList = fullMediaKitList
              .map(({ creatorUserId, offPlatformCreatorId }) => creatorUserId || offPlatformCreatorId)
              .filter((id, i, a) => id && a.indexOf(id) === i)

            const filteredAndSortedList = uniqueIdList
              .map((uniqueId) => {
                const mediaKitList = fullMediaKitList
                  .filter(({ creatorUserId, offPlatformCreatorId }) => creatorUserId === uniqueId || offPlatformCreatorId === uniqueId)

                const selectedMediaKit = mediaKitList.find(({ id }) => selectedMediaKitIdList.includes(id)) || mediaKitList[0] // default to the oldest media kit
                return selectedMediaKit
              })
              .sort((a, b) => (a.fullName || SORT_END_CHAR).localeCompare(b.fullName || SORT_END_CHAR) || a.id.localeCompare(b.id))

            this.mediaKitList = filteredAndSortedList
          }
        }
      },
    },
  },
  methods: {
    getPlatformIcon(platform) {
      if (platform) {
        if (platform === TIKTOK_NATIVE) return TiktokIcon
        if (platform === INSTAGRAM_NATIVE) return InstagramIcon
        if (platform === YOUTUBE_NATIVE) return YoutubeIcon
        if (platform === SNAPCHAT_NATIVE) return SnapchatIcon
      }
      return undefined
    },
    openMediaKit(mediaKit) { this.mediaKitPreview = mediaKit },
    closeMediaKit() { this.mediaKitPreview = undefined },
  },
}
