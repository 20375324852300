//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { numberMagnitudeString, formatNumber } from '@happstv/shared/util/utils'
import { TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE, SNAPCHAT_NATIVE } from '@happstv/shared/util/multicastingConstants'

import { CUSTOM_CURRENCY_LIST } from '@/util/console/brandCampaignConsoleUtils'
import { getAccountListFromMediaKit } from '@/util/agent/agencyUtils'

import UserProfilePhoto from '@/components/user/UserProfilePhoto.vue'

import MapPinIcon from '@/assets/svg/indicators/MapPinIcon.vue'
import TiktokIcon from '@/assets/svg/social/external/TiktokIcon.vue'
import InstagramIcon from '@/assets/svg/social/external/InstagramIconWhite.vue'
import YoutubeIcon from '@/assets/svg/social/external/YoutubeIcon.vue'
import SnapchatIcon from '@/assets/svg/social/external/SnapchatIcon.vue'

export default {
  name: 'PublicMediaKitPreview',
  components: {
    UserProfilePhoto,
    MapPinIcon,
  },
  props: {
    creatorList: Object,
    mediaKit: Object,
    isMobile: Boolean,
    hideRates: Boolean,
  },
  data() {
    return {
      numberMagnitudeString,
      YOUTUBE_NATIVE,

      ACCOUNT_METRICS_TO_DISPLAY: [
        {
          key: 'followerCount',
          label: 'Followers',
          get({ userResultStats, account }) {
            return userResultStats?.followerCount || account.followerCount
          },
        },
        {
          key: 'medianViewCount',
          label: 'Avg. Views',
          get({ userPostStats, account }) {
            return userPostStats?.medianViewCount || account.medianViewCount
          },
        },
      ],
    }
  },
  computed: {
    fullAccountList() {
      const { mediaKit = {} } = this
      return getAccountListFromMediaKit(mediaKit)
    },
    deliverableRate() {
      const { mediaKit } = this
      const { id: mediaKitId, rateCards = [] } = mediaKit || {}
      const { deliverableTypeId, manualRatesByMediaKit = {}, selectedDeliverableTypeByMediaKit = {} } = this.creatorList
      
      let currency, rate
      if (manualRatesByMediaKit[mediaKitId] !== undefined) { 
        ({ currency, rate } = manualRatesByMediaKit[mediaKitId] || {}) // Override a rate card with a manually set rate
      } else if (selectedDeliverableTypeByMediaKit[mediaKitId] !== undefined) {
        ({ currency, rate } = rateCards.find(({ type = {} }) => type.id === selectedDeliverableTypeByMediaKit[mediaKitId]) || {})
      } else {
        ({ currency, rate } = rateCards.find(({ type = {} }) => type.id === deliverableTypeId) || {})
      }

      return { currency, rate }
    },
    deliverableRateString() {
      const { currency, rate } = this.deliverableRate

      const selectedCurrency = CUSTOM_CURRENCY_LIST.find(({ key }) => key === currency)
      const selectedCurrencyPrefix = selectedCurrency.prefix || '$'
      const innerRateString = (rate !== undefined) ? formatNumber(rate) : ''
      return `${selectedCurrencyPrefix}${innerRateString}`
    },
    shouldShowRate() {
      const { hideRates = false, deliverableRate } = this
      if (hideRates) return false

      const { rate } = deliverableRate
      return rate !== undefined
    },
  },
  methods: {
    getPlatformIcon(platform) {
      if (platform) {
        if (platform === TIKTOK_NATIVE) return TiktokIcon
        if (platform === INSTAGRAM_NATIVE) return InstagramIcon
        if (platform === YOUTUBE_NATIVE) return YoutubeIcon
        if (platform === SNAPCHAT_NATIVE) return SnapchatIcon
      }
      return undefined
    },
    hasMetrics(account) {
      const { ACCOUNT_METRICS_TO_DISPLAY } = this
      return ACCOUNT_METRICS_TO_DISPLAY
        .some(({ get }) => get(account) !== undefined)
    },
  },
}
